import { format, parseISO, addHours } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const dateToString = date => {
  const parsedDate = parseISO(date)
  const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo')
  const addedDate = addHours(znDate, 1)
  const stringDate = format(new Date(addedDate), 'dd/MM/yyyy')

  return stringDate
}
