import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import api from '../../actions/api'
import { dateToString } from '../../actions/date'

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      status: '',
      contacts: []
    };
  }

  componentDidMount() {
    const { status } = this.props.match.params;

    this.setState({ status: status })

    this.getContacts(status);
  }


  getContacts = async status => {
    let contacts = [];

    await api.get(`contacts`)
      .then(result => {
        const data = result.data;

        if (status !== 'GERAL') {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];

            if (element.status === status) {
              element.date = dateToString(element.created_at)
              contacts.push(element)
            }

          }
        } else {
          contacts = data;
        }

        this.setState({ contacts: contacts })
      })
      .catch(error => {
        console.log(error);
      })
  }

  handleRowContact = id => {
    this.props.history.push(`/contacts/${id}`)
  }

  render() {
    const { contacts, status } = this.state;

    return (
      <div className="container mt-4">
        <div className="row p-0 m-0 justify-content-between">
          <h5> Contatos <b>{status}</b></h5>
          <Link to="/" className="link">
            <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
          </Link>
        </div>
        <hr />

        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th className="text-center" scope="col">Data</th>
                <th className="text-center" scope="col">Unidade</th>
                <th className="text-center" scope="col">Nome</th>
                <th className="text-center" scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, i) => (
                <tr key={i} onClick={() => this.handleRowContact(contact.id)}>
                  <th scope="row">{contact.id}</th>
                  <td className="text-center">{contact.date}</td>
                  <td className="text-center">{contact.unit}</td>
                  <td className="text-center">{contact.name}</td>
                  <td className="text-center">{contact.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {contacts.length >= 10 &&
          <div className="row p-0 m-0 justify-content-end">
            <div className="col-2">
              <Link to="/" className="link">
                <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}
