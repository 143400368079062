import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import api from '../../actions/api';
import { dateToString } from '../../actions/date';
import { toastError, toastSuccess } from '../../actions/toast';

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      id: '',
      landing: '',
      unit: '',
      name: '',
      email: '',
      cell_phone: '',
      phone: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      zip_code: '',
      prefer_contact: '',
      status: '',
      receive_promotions: '',
      date: ''
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getContact(id)
  }

  getContact = async id => {
    await api.get(`contacts/${id}`)
      .then(result => {
        const contact = result.data;
        this.setState({
          id: contact.id || '',
          landing: contact.landing || '',
          unit: contact.unit || '',
          name: contact.name || '',
          email: contact.email || '',
          cell_phone: contact.cell_phone || '',
          phone: contact.phone || '',
          street: contact.street || '',
          number: contact.number || '',
          complement: contact.complement || '',
          district: contact.district || '',
          city: contact.city || '',
          state: contact.state || '',
          zip_code: contact.zip_code || '',
          prefer_contact: contact.prefer_contact || '',
          status: contact.status || '',
          receive_promotions: contact.receive_promotions === 0 ? 'Não' : 'Sim',
          date: dateToString(contact.created_at) || '',
        })
      })
      .catch(error => {
        toastError(error);
      })
  }

  submit = e => {
    e.preventDefault();

    this.update();
  }

  update = async () => {
    const contact = this.state;

    contact.receive_promotions = contact.receive_promotions === 'Sim' ? 1 : 0

    await api.put(`contacts/${contact.id}`, contact)
      .then(result => {
        console.log('ok');
        toastSuccess('Registro atualizado com sucesso!')
      })
      .catch(error => {
        toastError(error);
      })
  }

  endContact = () => {
    this.setState({ status: 'CONCLUIDO' })

    setTimeout(() => {
      this.update();
    }, 500);
  }

  render() {
    const contact = this.state;
    return (
      <div className="container mt-4">
        <div className="col-12">
          <h5> Editando contato de <b>{contact.name}</b></h5>
          <hr />
        </div>

        <form onSubmit={this.submit}>
          <div className="row p-0 m-0">
            <div className="form-group col">
              <label>Data</label>
              <input
                type="text"
                className="form-control"
                value={contact.date}
                disabled
                onChange={e => this.setState({ date: e.target.value })} />
            </div>

            <div className="form-group col col-md-6">
              <label>Landing</label>
              <input
                type="text"
                className="form-control"
                value={contact.landing}
                disabled
                onChange={e => this.setState({ landing: e.target.value })} />
            </div>

            <div className="form-group col">
              <label>Unidade</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={contact.unit}
                onChange={e => this.setState({ unit: e.target.value })} />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="form-group col">
              <label>Status</label>
              <select
                className="form-control"
                value={contact.status}
                onChange={e => this.setState({ status: e.target.value })}>
                <option>PENDENTE</option>
                <option>ANDAMENTO</option>
                <option>CONCLUIDO</option>
              </select>
            </div>

            <div className="form-group col">
              <label>Melhor contato</label>
              <select
                className="form-control"
                value={contact.prefer_contact}
                onChange={e => this.setState({ prefer_contact: e.target.value })} >
                <option></option>
                <option>E-MAIL</option>
                <option>TELEFONE</option>
                <option>WHATSAPP</option>
              </select>

            </div>

            <div className="form-group col col-md-2">
              <label>Recebe promoção</label>
              <select
                className="form-control"
                value={contact.receive_promotions}
                onChange={e => this.setState({ receive_promotions: e.target.value })} >
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="form-group col ">
              <label>Nome</label>
              <input
                type="text"
                className="form-control"
                value={contact.name}
                onChange={e => this.setState({ name: e.target.value })} />
            </div>

            <div className="form-group col ">
              <label>E-mail</label>
              <input
                type="email"
                className="form-control"
                value={contact.email}
                onChange={e => this.setState({ email: e.target.value })} />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="form-group col ">
              <label>Celular</label>
              <input
                type="text"
                className="form-control"
                value={contact.cell_phone}
                onChange={e => this.setState({ cell_phone: e.target.value })} />
            </div>

            <div className="form-group col ">
              <label>Telefone</label>
              <input
                type="text"
                className="form-control"
                value={contact.phone}
                onChange={e => this.setState({ phone: e.target.value })} />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="form-group col ">
              <label>Rua</label>
              <input
                type="text"
                className="form-control"
                value={contact.street}
                onChange={e => this.setState({ street: e.target.value })} />
            </div>

            <div className="form-group col col-md-2">
              <label>Nº</label>
              <input
                type="text"
                className="form-control"
                value={contact.number}
                onChange={e => this.setState({ number: e.target.value })} />
            </div>

            <div className="form-group col ">
              <label>Complemento</label>
              <input
                type="text"
                className="form-control"
                value={contact.complement}
                onChange={e => this.setState({ complement: e.target.value })} />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="form-group col ">
              <label>Bairro</label>
              <input
                type="text"
                className="form-control"
                value={contact.district}
                onChange={e => this.setState({ district: e.target.value })} />
            </div>

            <div className="form-group col ">
              <label>Cidade</label>
              <input
                type="text"
                className="form-control"
                value={contact.city}
                onChange={e => this.setState({ city: e.target.value })} />
            </div>

            <div className="form-group col col-md-1">
              <label>Estado</label>
              <input
                type="text"
                className="form-control"
                value={contact.state}
                onChange={e => this.setState({ state: e.target.value })} />
            </div>

            <div className="form-group col col-md-2">
              <label>Cep</label>
              <input
                type="text"
                className="form-control"
                value={contact.zip_code}
                onChange={e => this.setState({ zip_code: e.target.value })} />
            </div>
          </div>

          <div className="row p-0 m-0 justify-content-end">
            <div className="col-3">
              <button type="submit" className="btn btn-primary btn-block">Atualizar</button>
            </div>

            {contact.status === 'ANDAMENTO' &&
              <div className="col-3">
                <button type="button" className="btn btn-danger btn-block" onClick={this.endContact}>Finalizar Contato</button>
              </div>
            }

            <div className="col-2">
              <Link to="/" className="link">
                <button type="button" className="btn btn-warning text-white btn-block">Voltar</button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
