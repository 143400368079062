import React, { Component } from 'react';
import api from '../../actions/api';

import './index.css';
import logo from '../../assets/images/logo.svg';

export default class Index extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      username: '',
      password: '',
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    const { username, password } = this.state;

    api
      .post('authenticate', { username, password })
      .then(res => {
        const { token } = res.data;
        localStorage.setItem('api_key', token);

        this.props.history.push('/home')
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { username, password } = this.state;

    return (
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="col col-md-4 mt-5 login-container mb-5">
            <div className="row justify-content-center mt-5">
              <img src={logo} alt="Logo" width="260" />
            </div>
            <p className="text-center mt-4 welcome mb-4">Bem vindo</p>

            <form className="form-login p-3 m-2" onSubmit={this.handleSubmit}>
              <div className="form-group login-input mt-3">
                <label htmlFor="user">Usuário</label>
                <input
                  type="text"
                  className="form-control"
                  id="user"
                  value={username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
              </div>

              <div className="form-group login-input mt-4">
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </div>

              <div className="form-group login-button mt-5">
                <button
                  type="submit"
                  className="btn btn-login btn-block btn-lg"
                >
                  Entrar
                </button>
              </div>
            </form>

            <div className="d-flex justify-content-center mb-5 ">
              <a className="forgot-password" href="mailto:username@domainname">
                Esqueci a senha
              </a>
            </div>
          </div>
        </div>
      </div>
      // <div className="container">
      //   <div className="content">
      //     <img src={logo} alt="Logo" width="260" />
      //     <p id="welcome">Bem vindo</p>
      //     <form onSubmit={this.handleSubmit}>
      //       <label htmlFor="username">Usuário</label>
      //       <input
      //         tid="username"
      //         type="text"
      //         value={username}
      //         onChange={e => this.setState({ username: e.target.value })}
      //       />
      //       <label htmlFor="password">Senha</label>
      //       <input
      //         id="password"
      //         type="password"
      //         value={password}
      //         onChange={e => this.setState({ password: e.target.value })}
      //       />
      //       <button type="submit">Entrar</button>
      //       <div id="forgot-password">
      //         <a href="mailto:username@domainname">Esqueci a senha</a>
      //       </div>
      //     </form>
      //   </div>
      // </div>
    );
  }
}
