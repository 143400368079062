import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import api from '../../actions/api'
import { dateToString } from '../../actions/date'

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      unit: '',
      city: '',
      contacts: []
    };
  }

  componentDidMount() {
    const { unit } = this.props.match.params;

    this.setState({ unit: unit })

    this.getContacts(unit);

    this.getCity(unit);
  }

  getCity = unit => {
    let city = '';

    if (unit === 'CDR') city = 'Caçador';
    if (unit === 'FRA') city = 'Fraiburgo';
    if (unit === 'JBA') city = 'Joaçaba';
    if (unit === 'CPZ') city = 'Capinzal';
    if (unit === 'AGD') city = 'Água Doce';
    if (unit === 'VDA') city = 'Videira';
    if (unit === 'CPN') city = 'Campos Novos';

    this.setState({ city: city });
  }

  getContacts = async unit => {
    let contacts = [];

    await api.get(`contacts`)
      .then(result => {
        const data = result.data;

        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.unit === unit) {
            element.date = dateToString(element.created_at)
            contacts.push(element)
          }

        }

        this.setState({ contacts: contacts })
      })
      .catch(error => {
        console.log(error);
      })
  }

  handleRowContact = id => {
    this.props.history.push(`/contacts/${id}`)
  }

  render() {
    const { contacts, city } = this.state;

    return (
      <div className="container mt-4">
        <div className="row p-0 m-0 justify-content-between">
          <h5> Contatos de <b>{city}</b></h5>
          <Link to="/" className="link">
            <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
          </Link>
        </div>
        <hr />

        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th className="text-center" scope="col">Data</th>
                <th className="text-center" scope="col">Unidade</th>
                <th className="text-center" scope="col">Nome</th>
                <th className="text-center" scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, i) => (
                <tr key={i} onClick={() => this.handleRowContact(contact.id)}>
                  <th scope="row">{contact.id}</th>
                  <td className="text-center">{contact.date}</td>
                  <td className="text-center">{contact.unit}</td>
                  <td className="text-center">{contact.name}</td>
                  <td className="text-center">{contact.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {contacts.length >= 10 &&
          <div className="row p-0 m-0 justify-content-end">
            <div className="col-2">
              <Link to="/" className="link">
                <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}
