import React from 'react'
import { Link } from 'react-router-dom'

const ResumeContacts = ({ contacts }) => (
  <div className="row mt-4">
    <div className="col-sm-12 col-md-4 mb-3">
      <div className="card border-danger bg-danger shadow resume">
        <Link to={`contacts-status/PENDENTE`}>
          <div className="card-body ">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-white mt-2">Total em espera</p>
              <span className="badge badge-secondary badge-pill mb-2" >{contacts.pending}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>

    <div className="col-sm-12 col-md-4 mb-3">
      <div className="card border-info bg-info shadow resume">
        <Link to={`contacts-status/ANDAMENTO`}>
          <div className="card-body ">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-white mt-2">Total em atendimento</p>
              <span className="badge badge-secondary badge-pill mb-2">{contacts.attending}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>

    <div className="col-sm-12 col-md-4 mb-3">
      <div className="card border-warning bg-warning shadow resume">
        <Link to={`contacts-status/GERAL`}>
          <div className="card-body ">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-white mt-2">Total de contatos</p>
              <span className="badge badge-secondary badge-pill mb-2">{contacts.total}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
)

export default ResumeContacts
