import styled from 'styled-components'

export const Container = styled.div`

  a {
    text-decoration:none;
  }

  .resume {
    :hover {
      p{
        font-weight: bold;
        color: #6c757d !important;
      }

      background-color: #f0e6e7 !important;
    }
  }

  .row-hover {
    :hover {
      background-color: #eceff2;
    }
  }

`
