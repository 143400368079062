import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import api from '../../actions/api';
import { dateToString } from '../../actions/date'
import './index.css';

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      unit: '',
      city: '',
      attendings: []
    };
  }

  componentDidMount() {
    const { unit } = this.props.match.params;

    this.setState({ unit: unit })

    this.getContacts(unit);

    this.getCity(unit);
  }

  getCity = unit => {
    let city = '';

    if (unit === 'CDR') city = 'Caçador';
    if (unit === 'FRA') city = 'Fraiburgo';
    if (unit === 'JBA') city = 'Joaçaba';
    if (unit === 'CPZ') city = 'Capinzal';
    if (unit === 'AGD') city = 'Água Doce';
    if (unit === 'VDA') city = 'Videira';
    if (unit === 'CPN') city = 'Campos Novos';

    this.setState({ city: city });
  }

  getContacts = async unit => {
    let attendings = [];

    await api.get(`contacts?status=ANDAMENTO`)
      .then(result => {
        const contacts = result.data;

        for (let i = 0; i < contacts.length; i++) {
          const element = contacts[i];
          if (element.unit === unit) {
            element.date = dateToString(element.created_at)
            attendings.push(element)
          }
        }

        this.setState({ attendings: attendings })
      })
      .catch(error => {
        console.log(error);
      })
  }

  handleRowContact = id => {
    this.props.history.push(`/contacts/${id}`)
  }

  render() {
    const { attendings, city } = this.state;

    return (
      <div className="container mt-4">
        <div className="row p-0 m-0 justify-content-between">
          <h5> Contatos em atendimento de <b>{city}</b></h5>
          <Link to="/" className="link">
            <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
          </Link>
        </div>
        <hr />

        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th className="text-center" scope="col">Data</th>
                <th className="text-center" scope="col">Unidade</th>
                <th className="text-center" scope="col">Nome</th>
                <th className="text-center" scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {attendings.map((attending, i) => (
                <tr key={i} onClick={() => this.handleRowContact(attending.id)}>
                  <th scope="row">{attending.id}</th>
                  <td className="text-center">{attending.date}</td>
                  <td className="text-center">{attending.unit}</td>
                  <td className="text-center">{attending.name}</td>
                  <td className="text-center">{attending.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {attendings.length >= 10 &&
          <div className="row p-0 m-0 justify-content-end">
            <div className="col-2 ">
              <Link to="/" className="link">
                <button type="button" className="btn btn-info text-white btn-block">Voltar</button>
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}
