
import React from 'react'
import { Link } from 'react-router-dom'

const CardCity = ({ city, base, unity }) => (
  <div className="col-sm-12 col-md-4 mb-3">
    <div className="card border-secondary shadow">
      <div className="card-header text-center"><h5>{city}</h5></div>
      <div className="card-body p-0">
        <Link to={`waiting/${unity}`}>
          <div className="row-hover px-3 py-1">
            <div className="d-flex justify-content-between align-items-center my-2">
              <p>Em espera</p>
              <span className="badge badge-danger badge-pill" >{base.pending}</span>
            </div>
          </div>
        </Link>

        <Link to={`attendings/${unity}`}>
          <div className="row-hover px-3 py-1">
            <div className="d-flex justify-content-between align-items-center my-2">
              <p>Em atendimento</p>
              <span className="badge badge-success badge-pill">{base.attending}</span>
            </div>
          </div>
        </Link>

        <Link to={`/contacts-unit/${unity}`}>
          <div className="row-hover px-3 py-1">
            <div className="d-flex justify-content-between align-items-center my-2">
              <p>Total de contatos</p>
              <span className="badge badge-secondary badge-pill">{base.total}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
)

export default CardCity
