import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Navbar from './components/Navbar'

import Routes from './routes';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={3500} />
      <Navbar />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
