import React, { Component } from 'react'
import ResumeContacts from './ResumeContacts'
import CardCity from './CardCity'

import api from '../../actions/api'
import './index.css';
import { Container } from './styles'

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      cacador: {},
      fraiburgo: {},
      joacaba: {},
      capinzal: {},
      videira: {},
      camposNovos: {},
      aguaDoce: {},
      contacts: {}
    };
  }

  componentDidMount() {
    this.getLandings()
  }

  getLandings = async () => {
    let cdr, fra, jba, vda, cpz, cpn, agd, contacts = {};

    await api.get('contacts')
      .then(result => {
        const data = result.data;

        cdr = this.getData('CDR', data)
        fra = this.getData('FRA', data)
        jba = this.getData('JBA', data)
        cpz = this.getData('CPZ', data)
        cpn = this.getData('CPN', data)
        vda = this.getData('VDA', data)
        agd = this.getData('AGD', data)
        contacts = this.getData(null, data)

      })
      .catch(error => {
        console.log(error);
      })

    this.setState({
      cacador: cdr,
      fraiburgo: fra,
      videira: vda,
      joacaba: jba,
      capinzal: cpz,
      camposNovos: cpn,
      aguaDoce: agd,
      contacts: contacts
    })
  }

  getData = (unit, data) => {
    let result = { pending: 0, pending_contact: '', attending: 0, total: 0 };
    let pendings = [];

    if (unit) {
      for (let i = 0; i < data.length; i++) {
        const cont = data[i];

        if (cont.unit === unit) {
          result.total += 1;

          if (cont.status === 'PENDENTE') {
            result.pending += 1;
            pendings.push(cont);
          } else if (cont.status === 'ANDAMENTO') {
            result.attending += 1
          }

          result.pending_contact = result.pending > 0 ? pendings[result.pending - 1] : null;
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        const cont = data[i];

        result.total += 1;

        if (cont.status === 'PENDENTE') {
          result.pending += 1;
          pendings.push(cont);
        } else if (cont.status === 'ANDAMENTO') {
          result.attending += 1
        }
      }
    }

    return result;
  }

  getPendingContact = async (contact) => {
    if (contact) {
      contact.status = 'ANDAMENTO';

      await api.put(`contacts/${contact.id}`, contact)
        .then(result => {
          this.props.history.push(`/contacts/${result.data.id}`);
        }).catch(error => {
          console.log(error);
        })
    }
  }

  render() {
    const { cacador, fraiburgo, joacaba, capinzal, camposNovos, videira, aguaDoce } = this.state;

    return (
      <Container className="container mt-3 ">
        <div className="row contacts justify-content-center mt-3">
          <CardCity city="Água Doce" base={aguaDoce} unity="AGD" />
          <CardCity city="Caçador" base={cacador} unity="CDR" />
          <CardCity city="Campos Novos" base={camposNovos} unity="CPN" />
          <CardCity city="Capinzal" base={capinzal} unity="CPZ" />
          <CardCity city="Fraiburgo" base={fraiburgo} unity="FRA" />
          <CardCity city="Joaçaba" base={joacaba} unity="JBA" />
          <CardCity city="Videira" base={videira} unity="VDA" />
        </div>

        <ResumeContacts contacts={this.state.contacts} />
      </Container >
    );
  }
}
