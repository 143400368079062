import React from 'react';
import { Switch, Route, Redirect } from 'react-router'
import { isAuthenticated } from './actions/auth'

import Login from './pages/login';
import Home from './pages/home';
import Contact from './pages/contact';
import ContactsUnit from './pages/contacts-unit';
import ContactsStatus from './pages/contacts-status';
import Attending from './pages/attending';
import Waiting from './pages/waiting';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
  )} />
)

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Home} />
    <Route path="/login" component={Login} />
    <PrivateRoute path="/home" component={Home} />
    <PrivateRoute path="/contacts/:id" component={Contact} />
    <PrivateRoute path="/contacts-unit/:unit" component={ContactsUnit} />
    <PrivateRoute path="/contacts-status/:status" component={ContactsStatus} />
    <PrivateRoute path="/attendings/:unit" component={Attending} />
    <PrivateRoute path="/waiting/:unit" component={Waiting} />
  </Switch>
)

export default Routes
