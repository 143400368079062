import React from 'react';
import { Link } from 'react-router-dom'
import { IoMdPower } from 'react-icons/io'

import { logoff } from '../../actions/auth'

import logo from '../../assets/images/logo_branca.svg';

export default function Navbar() {
  return (
    <nav className="navbar navbar-dark bg-dark">
      <div className="container">
        <div className="navbar-brand" >
          <Link to="/">
            <img src={logo} width="110" height="" alt="Logo" />
          </Link>
        </div>
        <h5 className="text-white mt-3">
          Landings
          <IoMdPower className="text-white mb-1 ml-2 link" onClick={logoff} />
        </h5>

      </div>
    </nav>
  );
}
