export const isAuthenticated = () => {
  if (localStorage.getItem('api_key')) {
    return true;
  } else {
    return false;
  }
}

export const login = (token) => {
  localStorage.setItem('api_key', token);
}

export const logoff = () => {
  localStorage.removeItem('api_key');
  window.location.reload();
}

export const getToken = () => {
  return localStorage.getItem('api_key');
}
