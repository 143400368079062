import React from 'react'
import { toast } from 'react-toastify';
import { FaRegCheckCircle } from 'react-icons/fa'
import { MdErrorOutline } from 'react-icons/md'

import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';

export const toastError = (text) => {

  const message = () => (
    <Container>
      <div className="icon">
        <MdErrorOutline size={22} />
      </div>
      <div className="classWithFontStyleOrWhatever">
        <p>Erro!</p>
        {text}
      </div>
    </Container>
  )

  return toast.error(message);
}

export const toastSuccess = (text) => {
  const message = () => (
    <Container>
      <div className="icon">
        <FaRegCheckCircle size={22} />
      </div>
      <div className="classWithFontStyleOrWhatever">
        <p>Sucesso!</p>
        {text}
      </div>
    </Container>
  )

  return toast.success(message);
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  p {
    font-size: 15px;
    font-weight: bold;
    margin: 0
  }

  .icon {
    margin: 10px;
    /* padding: 20px; */
    border-right: 2px solid #fff;

    svg {
      margin-right: 10px;
    }
  }
`
